import React from 'react';
import { Link } from 'react-router-dom';

const SupportNav = () => {
  return (
    <li className="navbar-item">
      <Link to="/support-us">Support Us</Link>
    </li>
  );
};

export default SupportNav;
