import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useVideo } from './VideoContext'; // Import the context
import AboutNav from './AboutNav';
import SupportNav from './SupportNav'; // Import the new SupportNav component
import AdminNav from './AdminNav';
import './Navbar.css';

const Navbar = () => {
  const [logoUrl, setLogoUrl] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const { stopAllVideos } = useVideo(); // Use the stopAllVideos function from the context

  useEffect(() => {
    const fetchLogoAndSchoolName = async () => {
      try {
        const logoResponse = await axios.get(`${process.env.REACT_APP_API_URL}api/logos/`);
        if (logoResponse.data && logoResponse.data.length > 0) {
          setLogoUrl(logoResponse.data[0].image_url);
        }

        const schoolResponse = await axios.get(`${process.env.REACT_APP_API_URL}api/schools/`);
        if (schoolResponse.data && schoolResponse.data.length > 0) {
          setSchoolName(schoolResponse.data[0].name);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchLogoAndSchoolName();
  }, []);

  return (
    <header className="navbar">
      <div className="navbar-logo" onClick={stopAllVideos}>
        <Link to="/">
          {logoUrl ? (
            <img src={logoUrl} alt="School Logo" className="logo" />
          ) : (
            <span>Loading logo...</span>
          )}
        </Link>
        <span className="navbar-title">{schoolName || 'Loading school name...'}</span>
      </div>
      <nav className="navbar-menu">
        <ul className="navbar-list">
          <AboutNav />
          <li className="navbar-item navbar-dropdown">
            <button className="navbar-dropbtn">Curriculum</button>
            <div className="navbar-dropdown-content">
              <Link to="/cbse-curriculum">CBSE Curriculum</Link>
              <Link to="/montessori-curriculum">Montessori Curriculum</Link>
            </div>
          </li>
          <li className="navbar-item navbar-dropdown">
            <button className="navbar-dropbtn">Events</button>
            <div className="navbar-dropdown-content">
              <Link to="/academic-events">Academic Events</Link>
              <Link to="/sports-events">Sports Events</Link>
            </div>
          </li>
          <li className="navbar-item navbar-dropdown">
            <button className="navbar-dropbtn">Gallery</button>
            <div className="navbar-dropdown-content">
              <Link to="/gallery/photo">Photo Gallery</Link>
              <Link to="/gallery/video">Video Gallery</Link>
            </div>
          </li>
          <SupportNav /> {/* Replaced ContactNav with SupportNav */}
          <AdminNav />
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
