import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './VideoList.css';
import { useVideo } from './VideoContext';

const VideoList = () => {
  const [videos, setVideos] = useState([]);
  const [activeVideo, setActiveVideo] = useState(null);
  const mainVideoRef = useRef(null);
  const { registerVideo } = useVideo();

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/videos/`);
        setVideos(response.data);
        setActiveVideo(response.data[0]);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };

    fetchVideos();
  }, []);

  useEffect(() => {
    if (mainVideoRef.current) {
      registerVideo(mainVideoRef.current);
    }
  }, [mainVideoRef, registerVideo]);

  const handleVideoClick = (video) => {
    setActiveVideo(video);
    if (mainVideoRef.current) {
      mainVideoRef.current.load();
    }
  };

  const renderVideo = (video) => {
    return (
      <video ref={mainVideoRef} controls autoPlay>
        <source src={video.src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    );
  };

  const renderThumbnail = (video) => {
    return (
      <img src={video.thumbnail} alt={video.title} className="video-thumbnail" />
    );
  };

  return (
    <div className="video-gallery-container">
      <h2 className="video-gallery-heading">Video Gallery</h2>
      <div className="video-list-container">
        {activeVideo && (
          <div className="main-video">
            {renderVideo(activeVideo)}
            <div className="main-video-description">
              <h3>{activeVideo.title}</h3>
              <p>{activeVideo.description}</p>
            </div>
          </div>
        )}
        <div className="videos-list">
          {videos.map((video, index) => (
            <div key={index} className="video-item" onClick={() => handleVideoClick(video)}>
              <h3>{video.title}</h3>
              <div className="thumbnail-wrapper">
                {renderThumbnail(video)}
              </div>
              <p>{video.short_description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoList;
