import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import SlidingBanner from './components/SlidingBanner';
import About from './components/About';
import CBSECurriculum from './components/CBSECurriculum';
import MontessoriCurriculum from './components/MontessoriCurriculum';
import AcademicEvents from './components/AcademicEvents';
import SportsEvents from './components/SportsEvents';
import PhotoGallery from './components/PhotoGallery';
import VideoList from './components/VideoList'; // Add VideoList import
import Contact from './components/Contact';
import AdminLogin from './components/AdminLogin';
import Features from './components/Features';
import Footer from './components/Footer';
import { VideoProvider } from './components/VideoContext'; // Import the VideoProvider
import SupportUs from './components/SupportUs'; // Import the SupportUs component
import './App.css';

function App() {
  return (
    <VideoProvider>
      <div className="container">
        <Navbar />
        <Routes>
          <Route path="/" element={<><SlidingBanner /><Features /></>} />
          <Route path="/about" element={<About />} />
          <Route path="/cbse-curriculum" element={<CBSECurriculum />} />
          <Route path="/montessori-curriculum" element={<MontessoriCurriculum />} />
          <Route path="/academic-events" element={<AcademicEvents />} />
          <Route path="/sports-events" element={<SportsEvents />} />
          <Route path="/gallery/photo" element={<PhotoGallery />} /> {/* Update Route */}
          <Route path="/gallery/video" element={<VideoList />} /> {/* Update Route */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/support-us" element={<SupportUs />} />
        </Routes>
        <Footer />
      </div>
    </VideoProvider>
  );
}

export default App;
