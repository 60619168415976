import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import App from './App';
import './index.css';

// Function to dynamically set the favicon
const setFavicon = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}api/logos/`);
    if (response.data && response.data.length > 0) {
      const faviconUrl = response.data[0].image_url; // Assuming the favicon URL is in image_url field
      const link = document.getElementById('dynamic-favicon');
      if (link) {
        link.href = faviconUrl;
      }
    }
  } catch (error) {
    console.error('Error fetching the favicon:', error);
  }
};

// Call the function to set the favicon
setFavicon();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
