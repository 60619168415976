import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './About.css'; // Import custom styles

const About = () => {
  const [briefDescriptions, setBriefDescriptions] = useState([]);
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    const fetchBriefDescriptions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/brief-descriptions/`);
        setBriefDescriptions(response.data);
      } catch (error) {
        console.error('Error fetching brief descriptions:', error);
      }
    };

    const fetchProfiles = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/profiles/`);
        setProfiles(response.data);
      } catch (error) {
        console.error('Error fetching profiles:', error);
      }
    };

    fetchBriefDescriptions();
    fetchProfiles();
  }, []);

  return (
    <div className="about-container">
      <h1>About Us</h1>
      {briefDescriptions.map((description, index) => (
        <div key={index}>
          <p dangerouslySetInnerHTML={{ __html: description.description }} />
        </div>
      ))}
      <h2>Our Team</h2>
      <div>
        {profiles.map((profile, index) => (
          <div 
            key={profile.id} 
            className={`profile-container ${index % 2 === 0 ? 'left' : 'right'}`}
          >
            {index % 2 === 0 && profile.image_url && (
              <img src={profile.image_url} alt={profile.name} className="profile-image left-image" />
            )}
            <div className="profile-description">
              <h3>{profile.name}</h3>
              <p><strong>Role:</strong> {profile.role}</p>
              <div dangerouslySetInnerHTML={{ __html: profile.bio }} />
            </div>
            {index % 2 !== 0 && profile.image_url && (
              <img src={profile.image_url} alt={profile.name} className="profile-image right-image" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
