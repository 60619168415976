import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './CBSECurriculum.css'; // Import custom styles

const CBSECurriculum = () => {
  const [curriculums, setCurriculums] = useState([]);

  useEffect(() => {
    const fetchCurriculums = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/cbse-curriculum/`);
        setCurriculums(response.data);
      } catch (error) {
        console.error('Error fetching CBSE curriculum:', error);
      }
    };

    fetchCurriculums();
  }, []);

  return (
    <div className="cbse-curriculum-container">
      <h1 className="cbse-curriculum-title">CBSE Curriculum</h1>
      {curriculums.map((curriculum) => (
        <div key={curriculum.id} className="curriculum-item">
          
          <p className="curriculum-content" dangerouslySetInnerHTML={{ __html: curriculum.content }} />
        </div>
      ))}
    </div>
  );
};

export default CBSECurriculum;
