import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './MontessoriCurriculum.css'; // Import custom styles

const MontessoriCurriculum = () => {
  const [curriculums, setCurriculums] = useState([]);

  useEffect(() => {
    const fetchCurriculums = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/montessori-curriculum/`);
        setCurriculums(response.data);
      } catch (error) {
        console.error('Error fetching Montessori curriculum:', error);
      }
    };

    fetchCurriculums();
  }, []);

  return (
    <div className="montessori-container">
      <h1 className="montessori-title">Montessori Curriculum</h1>
      {curriculums.map((curriculum) => (
        <div key={curriculum.id} className="curriculum-item">
          <p className="curriculum-content" dangerouslySetInnerHTML={{ __html: curriculum.content }} />
        </div>
      ))}
    </div>
  );
};

export default MontessoriCurriculum;
