import React from 'react';
import { Link } from 'react-router-dom';

const AboutNav = () => {
  return (
    <li className="navbar-item">
      <Link to="/about">About Us</Link>
    </li>
  );
};

export default AboutNav;
