import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PhotoGallery.css';

const PhotoGallery = () => {
  const [photos, setPhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  useEffect(() => {
    const fetchPhotos = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/photos/`);
        setPhotos(response.data);
      } catch (error) {
        console.error('Error fetching photos:', error);
      }
    };

    fetchPhotos();
  }, []);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        setSelectedPhoto(null);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const openModal = (photo) => {
    setSelectedPhoto(photo);
  };

  const closeModal = () => {
    setSelectedPhoto(null);
  };

  return (
    <div className="photo-gallery">
      <h1>Photo Gallery</h1>
      <div className="gallery">
        {photos.map((photo) => (
          <div key={photo.id} className="gallery-item" onClick={() => openModal(photo)}>
            <img src={photo.image} alt={photo.title} />
            {photo.title && <div className="photo-title">{photo.title}</div>}
          </div>
        ))}
      </div>

      {selectedPhoto && (
        <div className="modal" onClick={closeModal}>
          <span className="close">&times;</span>
          <div className="modal-content-wrapper">
            <img className="modal-content" src={selectedPhoto.image} alt={selectedPhoto.title} />
            {selectedPhoto.title && <div className="modal-caption">{selectedPhoto.title}</div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoGallery;
