import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './SupportUs.css'; // Import custom styles

const SupportUs = () => {
  const [supportContent, setSupportContent] = useState(null);

  useEffect(() => {
    const fetchSupportContent = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/support-content/`);
        setSupportContent(response.data[0]); // Assuming there is only one support content item
      } catch (error) {
        console.error('Error fetching support content:', error);
      }
    };

    fetchSupportContent();
  }, []);

  if (!supportContent) {
    return <p>Loading...</p>;
  }

  return (
    <div className="support-us-container">
      <h2>{supportContent.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: supportContent.content }} />
      <h3>Contact Information</h3>
      <p>Email: {supportContent.email}</p>
      <p>Phone: {supportContent.phone}</p>
    </div>
  );
};

export default SupportUs;
