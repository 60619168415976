import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Features.css';

const Features = () => {
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/features/`);
        setFeatures(response.data);
      } catch (error) {
        console.error('Error fetching features:', error);
      }
    };

    fetchFeatures();
  }, []);

  return (
    <div className="features-container">
      <h3 className="features-heading">Features in our school</h3>
      <div className="feature-boxes">
        {features.map((feature, index) => (
          <div key={index} className="feature-box">
            <img src={feature.image} alt={feature.title} className="feature-image" />
            <h3 className="feature-title">{feature.title}</h3>
            <p className="feature-description">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
