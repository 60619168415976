import React, { createContext, useContext, useState } from 'react';

const VideoContext = createContext();

export const useVideo = () => {
  return useContext(VideoContext);
};

export const VideoProvider = ({ children }) => {
  const [videos, setVideos] = useState([]);

  const registerVideo = (video) => {
    setVideos((prevVideos) => {
      if (!prevVideos.includes(video)) {
        return [...prevVideos, video];
      }
      return prevVideos;
    });
  };

  const stopAllVideos = () => {
    videos.forEach((video) => {
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
    });
  };

  return (
    <VideoContext.Provider value={{ registerVideo, stopAllVideos }}>
      {children}
    </VideoContext.Provider>
  );
};
