import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import './SlidingBanner.css'; // Import custom styles

const SlidingBanner = () => {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/banners/`);
        if (response.data) {
          setBanners(response.data);  // Assuming the banner URLs are in response data
        }
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    };

    fetchBanners();
  }, []);

  return (
    <div className="sliding-banner">
      {banners.length > 0 ? (
        <Carousel
          showArrows={true}
          autoPlay={true}
          infiniteLoop={true}
          interval={3000}
          showThumbs={false}
        >
          {banners.map((banner, index) => (
            <div key={index}>
              <img src={banner.image_url} alt={`Slide ${index + 1}`} />
            </div>
          ))}
        </Carousel>
      ) : (
        <p>Loading banners...</p> // Fallback content while banners are being fetched
      )}
    </div>
  );
};

export default SlidingBanner;
