import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './SportsEvents.css'; // Optional: For custom styles

const SportsEvents = () => {
  const [events, setEvents] = useState([]);
  const [showPastEvents, setShowPastEvents] = useState(false);
  const [filteredEvents, setFilteredEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/sports-events/`);
        setEvents(response.data);
      } catch (error) {
        console.error('Error fetching sports events:', error);
      }
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    const filterAndSortEvents = () => {
      const currentDate = new Date().toISOString().split('T')[0];
      let filtered = [];

      if (showPastEvents) {
        filtered = events.filter(event => event.event_date < currentDate).sort((a, b) => new Date(b.event_date) - new Date(a.event_date));
      } else {
        filtered = events.filter(event => event.event_date >= currentDate).sort((a, b) => new Date(a.event_date) - new Date(b.event_date));
      }

      setFilteredEvents(filtered);
    };

    filterAndSortEvents();
  }, [events, showPastEvents]);

  const handleTogglePastEvents = () => {
    setShowPastEvents(!showPastEvents);
  };

  return (
    <div className="sports-events-container">
      <h1>Sports Events</h1>
      <button onClick={handleTogglePastEvents}>
        {showPastEvents ? 'Show Current and Future Events' : 'Show Past Events'}
      </button>
      {filteredEvents.length > 0 ? (
        <ul className="events-list">
          {filteredEvents.map(event => (
            <li key={event.id} className="event-item">
              <h2>{event.event_name}</h2>
              <p><strong>Date:</strong> {event.event_date}</p>
              <p><strong>Time:</strong> {event.event_time}</p>
              <p><strong>Venue:</strong> {event.venue}</p>
              {event.organizer && <p><strong>Organizer:</strong> {event.organizer}</p>}
              {event.description && <p><strong>Description:</strong> <span dangerouslySetInnerHTML={{ __html: event.description }} /></p>}
              {event.participants && <p><strong>Participants:</strong> {event.participants}</p>}
              {event.sport_type && <p><strong>Sport Type:</strong> {event.sport_type}</p>}
              {event.teams_involved && <p><strong>Teams Involved:</strong> {event.teams_involved}</p>}
              {event.age_group && <p><strong>Age Group/Grade Level:</strong> {event.age_group}</p>}
              {event.registration_required && <p><strong>Registration Required:</strong> {event.registration_required ? 'Yes' : 'No'}</p>}
              {event.registration_deadline && <p><strong>Registration Deadline:</strong> {event.registration_deadline}</p>}
              {event.contact_information && <p><strong>Contact Information:</strong> {event.contact_information}</p>}
              {event.event_poster && (
                <div>
                  <strong>Event Poster:</strong>
                  <img src={event.event_poster} alt={event.event_name} className="event-poster" />
                </div>
              )}
              {event.event_schedule && <p><strong>Event Schedule:</strong> <span dangerouslySetInnerHTML={{ __html: event.event_schedule }} /></p>}
              {event.special_guests && <p><strong>Special Guests:</strong> {event.special_guests}</p>}
              {event.prizes_awards && <p><strong>Prizes and Awards:</strong> {event.prizes_awards}</p>}
              {event.equipment_needed && <p><strong>Equipment Needed:</strong> {event.equipment_needed}</p>}
              {event.cost && <p><strong>Cost:</strong> {event.cost}</p>}
            </li>
          ))}
        </ul>
      ) : (
        <p>No sports events found.</p>
      )}
    </div>
  );
};

export default SportsEvents;
