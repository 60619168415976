import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Footer.css'; // Import custom styles

const Footer = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [contactDetails, setContactDetails] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const fetchContactDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/contact-details/`);
        setContactDetails(response.data[0]); // Assuming there's only one contact detail entry
      } catch (error) {
        console.error('Error fetching contact details:', error);
      }
    };

    fetchContactDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/enquiries/`, formData);
      console.log('Form data submitted:', response.data);
      // Clear the form
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: ''
      });
      // Show the popup
      setShowPopup(true);
      // Hide the popup after 3 seconds
      setTimeout(() => setShowPopup(false), 3000);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <footer className="footer">
      {showPopup && (
        <div className="popup">
          <p>Enquiry successfully sent!</p>
        </div>
      )}
      <div className="footer-container">
        {contactDetails && (
          <div className="footer-section">
            <h4>Contact Us</h4>
            <p>{contactDetails.school_name}</p>
            <p>{contactDetails.address}</p>
            <p>Phone: {contactDetails.phone}</p>
            <p>Email: {contactDetails.email}</p>
          </div>
        )}
        <div className="footer-section">
          <h4>Quick Links</h4>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about">About Us</a></li>
            <li><a href="/academic-events">Academic Events</a></li>
            <li><a href="/sports-events">Sports Events</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>
        {/* <div className="footer-section">
          <h4>Follow Us</h4>
          <div className="social-media">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
          </div>
        </div> */}
        <div className="footer-section">
          <h4>Enquiry Form</h4>
          <form onSubmit={handleSubmit} className="enquiry-form">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your Name"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your Email"
            />
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Your Phone"
              required
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Your Message"
              required
            ></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 British International School CBSE. All rights reserved.</p>
        <p>Site developed by T.P.Thangaprabhu B.Sc., MBA., CIMA ADV DIP MA (UK), FCMA., ACPIFSD (IIT - Roorkee)</p>
      </div>
    </footer>
  );
};

export default Footer;
