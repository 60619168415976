import React, { useState } from 'react';
import './AdminLogin.css';

const AdminLogin = () => {
  const [category, setCategory] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your login logic here
    console.log('Category:', category);
    console.log('Username:', username);
    console.log('Password:', password);
  };

  return (
    <div className="admin-login">
      <h1>Admin Login</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="category">Category:</label>
          <select id="category" value={category} onChange={handleCategoryChange}>
            <option value="">Select Category</option>
            <option value="correspondent">Correspondent</option>
            <option value="principal">Principal</option>
            <option value="teachers">Teachers</option>
          </select>
        </div>
        <div>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={handleUsernameChange}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default AdminLogin;
